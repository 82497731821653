
  import { Component, Watch } from 'vue-property-decorator'
  import { plainToInstance } from 'class-transformer'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixAmountFormatted, fixPrice, isValidNumber, parseToNumber, stringifySafe } from '@/utils/general'
  import { Enablement, InspectionQualification } from '@/entities/purchase'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Debounce } from '@/utils/decorators'
  import { EnablementView } from '@/components/forms/view/EnablementView'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import { City } from '@/entities/public'
  import { Person } from '@/entities/persons'
  import dayjs from 'dayjs'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, GRadioButton, GFiles, LinkedPerson, BaseForm },
  methods: { fixAmountFormatted, fixPrice, isValidNumber },
  computed: {},
})
  export default class EnablementForm extends EnablementView {
  componentKeys = ['circulationPermit', 'soap', 'technicalReview']
  enablement: Enablement = plainToInstance(Enablement, {})
  components = []
  message = ''

  processInspectionId = null
  processEnablementId = null
  enablementInspectionComponent = []
  idInspectionInspectedComponent = null
  auto = null
  amount = null
  displayAmount = false
  disabledAmount = false
  showDetail = false
  total = null
  expiration = {
    circulationPermit: null,
    soap: null,
    technicalReview: null,
  }

  component = []
  cities: City[] = []
  plants: City[] = []
  insurances: Person[] = []
  equipmentComponents = null
  errorMessage = {
    mileage: '',
  }

  errorIdentifierMessage = {
    status: {
      status: '',
      provisional: ''
      ,
    },
  }

  title = ''
  mileage = null
  panels = []
  section = {
    documents: false,
  }

  radioButtonMessage = {
    bodyWork: '',
    wheels: '',
    inside: '',
    mechanical: '',
    clean: '',
    answerDocuments: '',
  }

  formData = {
    key: {
      status: {
        answer: null,
        provisional: null,
      },
      copy: {
        answer: null,
      },
      cost: undefined,
      photo: [],
    },
    bodyWork: {
      answer: false,
      photo: [],
      cost: null,
    },
    wheels: {
      answer: false,
      photo: [],
      cost: null,
    },
    inside: {
      answer: false,
      photo: [],
      cost: null,
    },
    mechanical: {
      answer: false,
      photo: [],
      cost: null,
    },
    clean: {
      answer: false,
      photoFront: [],
      photoRear: [],
      photoChassis: [],
      photoEngine: [],
      cost: null,
    },
    answerDocuments: null,
    answerDocumentsCost: null,
    mileage: null,
    mileagePhoto: {
      photo: [],
    },
    soap: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
      inspectionDate: null,
    },
    technicalReview: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
      inspectionDate: null,
    },
    circulationPermit: {
      selection: null,
      date: null,
      amount: null,
      photo: null,
      cost: null,
      inspectionDate: null,
    },
  }

  fields = {
    key: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    mileagePhoto: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    circulationPermit: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    technicalReview: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    soap: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    bodyWork: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    wheels: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    inside: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    mechanical: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    frontPilot: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    rearPilot: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    chassis: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    engine: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
  }

  metadata = {}
  metadataCollection = {}

  async mounted () {
    this.loadingForm = true

    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: {},
    })

    await this.setMetadata()
    const { uid, id, title, metadataCollection } = this
    const enablementId = uid || id

    if (isValidNumber(enablementId)) {
      await this.getEnablementInfo(enablementId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(enablementId))
    }

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          id: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
    })

    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
    })
    this.insurances = companyType?.[0]?.persons

    this.displayDetail()
    this.loadingForm = false
  }

  async setMetadata () {
    const { metadata } = this.getForm('Enablement', 'inspector_enablement')
    const { form } = metadata as Form
    this.title = form.title
    this.metadataCollection = metadata

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.processInspectionId = processInspection?.[0]?.id

    const processEnablement = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement' } },
    })

    this.processEnablementId = processEnablement?.[0]?.id

    const equipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Equipamiento' } },
    })

    this.equipmentComponents = equipment?.[0]?.components

    this.components = await this.fetchData({
      query: { name: 'find', model: 'InspectionComponent' },
      filter: { _or: [{ component: { category: { name: { _in: ['Documentación', 'Identificación'] } } } }, { component_value: { value: { _eq: 'Llaves' } } }] },
    })
  }

  async getEnablementInfo (id) {
    if (!id) return
    this.enablement = await this.fetchData({
      query: { name: 'fetch', model: 'Enablement', params: { id } },
      force: true,
    })

    const stockViewDetails = await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { stock: { id_enablement: { _eq: id } } },
    })
    this.enablement.stock.viewDetails = stockViewDetails[0]
    await this.setFormDataValues()
    const idAuto = this.enablement?.inspectionView[0]?.idAuto || this.enablement?.stock?.viewDetails?.idAuto
    if (idAuto) {
      this.auto = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id: idAuto } },
      })
    }

    this.enablement.auto = this.auto

    this.enablementInspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: id } },
            { process: { table_name: { _eq: 'enablement' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación', 'Identificación'] } } } } },
                { inspection_component: { component_value: { value: { _eq: 'Llaves' } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })

    await this.setFilesData()
  }

  async setFormDataValues () {
    this.formData.clean.answer = this.enablement.metadata?.inspector.clean?.value
    this.formData.clean.cost = this.enablement.metadata?.inspector.clean?.cost
    this.formData.mechanical.answer = this.enablement.metadata?.inspector.mechanical?.value
    this.formData.mechanical.cost = this.enablement.metadata?.inspector.mechanical?.cost
    this.formData.inside.answer = this.enablement.metadata?.inspector.inside?.value
    this.formData.inside.cost = this.enablement.metadata?.inspector.inside?.cost
    this.formData.wheels.answer = this.enablement.metadata?.inspector.wheels?.value
    this.formData.wheels.cost = this.enablement.metadata?.inspector.wheels?.cost
    this.formData.bodyWork.answer = this.enablement.metadata?.inspector.bodyWork?.value
    this.formData.bodyWork.cost = this.enablement.metadata?.inspector.bodyWork?.cost
    this.formData.answerDocuments = this.enablement.metadata?.inspector.documentation.value
    this.formData.answerDocumentsCost = this.enablement.metadata?.inspector.documentation.cost

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.enablement.id } },
          { parameter: { process: { id: { _eq: this.processEnablementId } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    this.formData.bodyWork.photo = files.filter(file => file.parameter.name === 'bodywork_cost')
    this.formData.wheels.photo = files.filter(file => file.parameter.name === 'wheels_cost')
    this.formData.inside.photo = files.filter(file => file.parameter.name === 'inside_cost')
    this.formData.mechanical.photo = files.filter(file => file.parameter.name === 'mechanical_cost')
    this.formData.clean.photoFront = files.filter(file => file.parameter.name === 'front_pilot')
    this.formData.clean.photoRear = files.filter(file => file.parameter.name === 'rear_pilot')
    this.formData.clean.photoChassis = files.filter(file => file.parameter.name === 'chassis')
    this.formData.clean.photoEngine = files.filter(file => file.parameter.name === 'engine')
  }

  async setFilesData () {
    const { circulationPermit, soap, technicalReview, key, mileageComponent } = this

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'inspection_inspected_component' } } },
    })

    this.setProperties(fileInfo, 'mileagePhoto')
    this.setProperties(fileInfo, 'circulationPermit')
    this.setProperties(fileInfo, 'soap')
    this.setProperties(fileInfo, 'technicalReview')
    this.setProperties(fileInfo, 'key')

    await this.setEnablementFiles()

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })
    this.idInspectionInspectedComponent = process?.[0]?.id
    if (circulationPermit?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(circulationPermit[0].idInspectedComponent, 'circulationPermit', process?.[0]?.id)
    }
    if (soap?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(soap[0].idInspectedComponent, 'soap', process?.[0]?.id)
    }
    if (technicalReview?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(technicalReview[0].idInspectedComponent, 'technicalReview', process?.[0]?.id)
    }

    if (key?.[0]?.idInspectedComponent && this.getInspectionViewByOrder(this.key, 2) instanceof InspectionQualification) {
      this.formData.key.copy.answer = this.getInspectionViewByOrder(this.key, 2).assessment.id
      this.formData.key.cost = this.getInspectionViewByOrder(this.key, 2).component.cost
      await this.setPhotoToForm(key[0].idInspectedComponent, 'key', process?.[0]?.id)
    }

    const isMileageInspectionQualification = mileageComponent.some(item => item instanceof InspectionQualification)

    if (mileageComponent?.[0]?.idInspectedComponent && isMileageInspectionQualification) {
      await this.setPhotoToForm(mileageComponent[0].idInspectedComponent, 'mileagePhoto', process?.[0]?.id)
    }
  }

  async setPhotoToForm (idProcessRecord, fieldKey, idProcess) {
    if (!idProcessRecord) return

    this.formData[fieldKey].photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    if (['mileagePhoto'].includes(fieldKey)) {
      return
    }

    const { getInspectionViewByOrder } = this

    if (['key'].includes(fieldKey)) {
      return
    }

    const date = getInspectionViewByOrder(this[fieldKey], 2)?.value

    this.formData[fieldKey].date = DateGenerator.findGeneratedDate(date).internal
    this.formData[fieldKey].inspectionDate = DateGenerator.findGeneratedDate(date).internal
    if (fieldKey === 'circulationPermit') {
      this.formData[fieldKey].selection = { name: getInspectionViewByOrder(this[fieldKey], 1).value }
      this.formData[fieldKey].amount = getInspectionViewByOrder(this[fieldKey], 3).value
      this.formData[fieldKey].cost = getInspectionViewByOrder(this[fieldKey], 1).component.cost

      await this.searchCity(this.formData[fieldKey].selection.name)
    }

    if (fieldKey === 'soap') {
      this.formData[fieldKey].selection = { alias: getInspectionViewByOrder(this[fieldKey], 1).value }
      this.formData[fieldKey].cost = getInspectionViewByOrder(this[fieldKey], 1).component.cost
      await this.searchCompany(this.formData[fieldKey].selection.alias)
    }

    if (fieldKey === 'technicalReview') {
      this.formData[fieldKey].selection = { alias: getInspectionViewByOrder(this[fieldKey], 1).value }
      this.formData[fieldKey].cost = getInspectionViewByOrder(this[fieldKey], 1).component.cost
      await this.searchPlants(this.formData[fieldKey].selection.alias)
    }
  }

  async setEnablementFiles () {
    const fileInfoEnablement = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'enablement' } } },
    })

    this.setPropertiesBySlug(fileInfoEnablement, 'front_pilot', 'frontPilot')
    this.setPropertiesBySlug(fileInfoEnablement, 'rear_pilot', 'rearPilot')
    this.setPropertiesBySlug(fileInfoEnablement, 'chassis', 'chassis')
    this.setPropertiesBySlug(fileInfoEnablement, 'engine', 'engine')
    this.setPropertiesBySlug(fileInfoEnablement, 'bodywork_cost', 'bodyWork')
    this.setPropertiesBySlug(fileInfoEnablement, 'wheels_cost', 'wheels')
    this.setPropertiesBySlug(fileInfoEnablement, 'inside_cost', 'inside')
    this.setPropertiesBySlug(fileInfoEnablement, 'mechanical_cost', 'mechanical')
  }

  setPropertiesBySlug (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  async preSend () {
    this.panels = [0, 1, 2, 3, 4, 5, 6]
    await this.send()
  }

  displayDetail () {
    const { enablement, metadataCollection } = this

    this.metadata = {
      data: enablement,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(enablement.id)
  }

  sectionValidation () {
    const keys = ['answerDocuments']
    let response = false
    keys.forEach(key => {
      if (this.formData[key] === null) {
        this.radioButtonMessage[key] = 'Campo requerido'
        response = true
      }
    })

    return response
  }

  async send () {
    const { message, errorMessage } = this
    const isSomeError = errorMessage.mileage.length

    if (!this.$refs.form.validate() || message.length || this.sectionValidation() || isSomeError) {
      return
    }
    this.loadingForm = true
    await this.handleEnablementExecutive(this)
    this.loadingForm = false
    await this.close()
  }

  get change () {
    const { formData, panels } = this

    return stringifySafe([formData, panels])
  }

  @Watch('enablement', { immediate: true, deep: true })
  @Debounce(100)
  async onDealAutoAttributeDataChange (val) {
    if (!val?.id) return

    const filter = {
      _and: [
        { id_process_record: { _eq: val?.idInspection } },
        { process: { table_name: { _eq: 'inspection' } } },
        { component: { slug: { _eq: 'mileage' } } },
      ],
    }

    const attribute = await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter,
      force: true,
    })

    this.mileage = attribute?.[0]
    const filter2 = {
      _and: [
        { id_process_record: { _eq: val?.id } },
        { process: { table_name: { _eq: 'enablement' } } },
        { component: { slug: { _eq: 'mileage' } } },
      ],
    }

    const attributeEnablement = await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: filter2,
      force: true,
    })
    this.formData.mileage = attributeEnablement?.[0]?.value
  }

  get enablementIsToUpdate () {
    const { enablement } = this
    return enablement?.status?.isToUpdating || enablement?.status?.isPending || enablement?.status?.isActive
  }

  get enablementStatusIsDisabled () {
    const { enablement } = this

    return enablement?.status?.isClosed || enablement?.status?.isToConfirm
  }

  get basicEquipment () {
    return this.findComponentBySlug(this.equipmentComponents, 'basic_equipment')
  }

  get keyComponent () {
    return this.basicEquipment?.values.find(val => val.value === 'Llaves')
  }

  get keyInspection () {
    const { enablement: { inspectionKey } } = this
    return inspectionKey?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')
  }

  get key () {
    const { enablement: { inspectionKey }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')

      if (components.length) {
        return components
      }
    }

    return inspectionKey?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')
  }

  get mileageComponent () {
    const { enablement: { inspectionIdentifiers }, enablementInspectionComponent } = this
    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'mileage')

      if (components.length) {
        return components
      }
    }

    return inspectionIdentifiers?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'mileage')
  }

  get circulationPermit () {
    const { enablement: { inspectionDocumentations }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')

      if (components.length) {
        return components
      }
    }

    return inspectionDocumentations?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
  }

  get soap () {
    const { enablement: { inspectionDocumentations }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
      if (components.length) {
        return components
      }
    }

    return inspectionDocumentations?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
  }

  get technicalReview () {
    const { enablement: { inspectionDocumentations }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
      if (components.length) {
        return components
      }
    }

    return inspectionDocumentations?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
  }

  @Watch('formData.mileage', { immediate: true, deep: true })
  onMileageValueChange (val) {
    if (!val) return

    if (Number(val) < Number(this.mileage?.value)) {
      this.errorMessage.mileage = 'El kilometraje no puede ser menor al registrado en la inspección'
    } else {
      this.errorMessage.mileage = ''
    }
  }

  async searchCity (val) {
    if (!val) return
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _ilike: `%${val}%` } },
    })
  }

  async searchCompany (val) {
    if (!val) return

    this.insurances = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: `insurance_carrier` } }, alias: { _ilike: `%${val}%` } },
    })
  }

  async searchPlants (val) {
    if (!val) return

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          id: 'asc',
        },
      },
      filter: { _and: [{ company_type: { name: { _eq: `technical_review_plant` } } }, { alias: { _ilike: `%${val}%` } }] },
    })
  }

  get isCalculableCost () {
    const { formData } = this

    return {
      amount: formData.circulationPermit.amount,
      date: formData.circulationPermit.date,
    }
  }

  @Watch('isCalculableCost', { immediate: true, deep: true })
  onCalculableCost (val) {
    if (this.loadingForm) return
    if (val.amount) {
      this.formData.circulationPermit.cost = Math.round(parseToNumber(val.amount) * this.delayPeriod)
    }
  }

  get delayPeriod () {
    const { circulationPermit, formData } = this

    const date = circulationPermit?.[0]?.date || dayjs()

    return DateGenerator.calculateMonthsDifference(formData.circulationPermit.date, date)
  }

  get expirationCirculation () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.circulationPermit.date) || -1
  }

  get enablementHasInspection () {
    const { enablement } = this

    return Boolean(enablement?.inspectionView?.length)
  }

  get expirationPeriodSoap () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.soap.date)
  }

  get expirationPeriodTechnicalReview () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.technicalReview.date)
  }

  get ppu () {
    const { auto } = this

    return auto?.ppu?.split('')[auto?.ppu.length - 1]
  }

  get autoYear () {
    const { auto } = this

    return auto?.year
  }

  get technicalDates () {
    const { ppu, autoYear } = this

    if (!ppu) return []

    return DateGenerator.calculateMonthByDigit(parseToNumber(ppu), parseToNumber(autoYear))
  }

  evaluateDeadline (deadline) {
    if (deadline === null || deadline === undefined) {
      return null
    }
    if (deadline < 0) {
      return 2
    }
    if (deadline === 0) {
      return 1
    }
    if (deadline > 0) {
      return 0
    }
  }

  @Watch('auto', { immediate: true, deep: true })
  async onInspectionChange (val) {
    if (!val?.id) return

    const { generation } = val
    let gen

    if (generation?.id) {
      gen = await this.fetchData({
        query: { name: 'fetch', model: 'Generation', params: { id: generation.id } },
      })
    }
    const { enablement } = this
    const date = enablement.inspectionView?.[0]?.date.year() || dayjs().year()
    const registration = gen?.registration?.find(registration => parseToNumber(registration.year) === parseToNumber(date))

    this.amount = registration?.permission
    if (!this.formData.circulationPermit.amount) {
      this.formData.circulationPermit.amount = registration?.permission
    }
    this.displayAmount = true
    this.disabledAmount = Boolean(registration?.permission)

    if (!isValidNumber(this.total) && val.metadata?.findCategoryByName('Documentación')) {
      this.total = val.metadata?.findCategoryByName('Documentación')?.total
    }
  }

  @Watch('formData.circulationPermit.amount', { immediate: true })
  onAmountChange (val) {
    if (!val || this.loadingForm) return

    const { amount } = this
    if (!amount) return
    const cost = amount - parseToNumber(val)

    if (cost > 0) {
      this.formData.circulationPermit.cost = cost
    } else if (!this.loadingForm) {
      this.formData.circulationPermit.cost = 0
    }
  }

  get generateDates () {
    const date = dayjs().year()

    return DateGenerator.generateDates(date)
  }

  get generateSoapDates () {
    const date = dayjs().year()

    return DateGenerator.generateDates(date, false)
  }

  get isCirculationPermit () {
    const { circulationPermit } = this

    return circulationPermit?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isSoap () {
    const { soap } = this

    return soap?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isTechnicalReview () {
    const { technicalReview } = this

    return technicalReview?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isOnlyOnceKey () {
    const { keyComponent, formData: { key: { copy } }, getQuestionOptions } = this
    if (!copy?.answer) return false

    return getQuestionOptions(keyComponent, 2).find(item => item.id === copy.answer)?.score
  }

  get isNewVehicle () {
    const { enablement } = this

    return !enablement.inspectionView?.length
  }

  get displayPhoto () {
    const { keyComponent, formData: { key: { copy } }, getQuestionOptions } = this
    if (!copy?.answer) return false

    return !getQuestionOptions(keyComponent, 2).find(item => item.id === copy.answer)?.score
  }

  get isKeyAnswerLower () {
    const { formData: { key: { copy } }, getInspectionViewByOrder, keyInspection, getQuestionOptions, keyComponent } = this

    if (!keyInspection || !keyComponent) return false

    const question = getQuestionOptions(keyComponent, 2).find(item => item.id === copy.answer)
    if (!question) return false

    const key = parseToNumber(getInspectionViewByOrder(keyInspection, 2)?.assessment.name)
    const keyAnswer = parseToNumber(question.option.name)

    return keyAnswer < key
  }

  get isEnablementMetadata () {
    const { enablement } = this

    return enablement?.metadata?.supervisor
  }

  @Watch('expirationCirculation', { immediate: true })
  onExpirationCirculationChange (val) {
    if (this.loadingForm) return
    if (this.isNewVehicle) {
      this.expiration.circulationPermit = true
      return
    }
    if (val < 0 && this.enablement?.inspectionView?.length && !this.expiration.circulationPermit) {
      this.expiration.circulationPermit = true
      this.formData.circulationPermit.date = null
      this.formData.circulationPermit.selection = null
      this.formData.circulationPermit.photo = []
    }
  }

  @Watch('expirationPeriodSoap', { immediate: true })
  onExpirationSoapChange (val) {
    if (this.loadingForm) return
    if (this.isNewVehicle) {
      this.expiration.soap = true
      if (val < 0) {
        this.formData.soap.cost = this.components?.find(ic => ic.component.slug === 'soap')?.config?.cost
      } else {
        this.formData.soap.cost = null
      }
      return
    }
    if (val < 0 && !this.expiration.soap) {
      this.expiration.soap = true
      this.formData.soap.selection = null
      this.formData.soap.photo = []
      this.formData.soap.date = null
    }
    if (val >= 1 && !this.expiration.soap) {
      this.formData.soap.cost = null
    } else {
      this.formData.soap.cost = this.components?.find(ic => ic.component.slug === 'soap')?.config?.cost
    }
  }

  get technicalReviewInfo () {
    const { expirationPeriodTechnicalReview, components } = this

    return {
      expirationPeriodTechnicalReview,
      components,
    }
  }

  @Watch('formData.answerDocuments', { immediate: true, deep: true })
  onAnswerDocumentsChange (val) {
    if (val) {
      const { formData: { key, soap, technicalReview, circulationPermit } } = this
      this.formData.answerDocumentsCost = (Number(soap.cost) || 0) + (Number(technicalReview.cost) || 0) + (Number(circulationPermit.cost) || 0) + (Number(key.cost) || 0)
    } else {
      this.formData.answerDocumentsCost = 0
    }
  }

  @Watch('technicalReviewInfo', { immediate: true, deep: true })
  onExpirationTechnicalReviewChange (val) {
    if (this.loadingForm) return
    if (this.isNewVehicle) {
      this.expiration.technicalReview = true
      if (val.expirationPeriodTechnicalReview < 2) {
        this.formData.technicalReview.cost = val.components?.find(ic => ic.component.slug === 'technical_review')?.config?.cost
      } else {
        this.formData.technicalReview.cost = null
      }
      return
    }

    if (val.expirationPeriodTechnicalReview < 0 && !this.expiration.technicalReview) {
      this.expiration.technicalReview = true
      this.formData.technicalReview.selection = null
      this.formData.technicalReview.photo = []
    }

    if (val.expirationPeriodTechnicalReview >= 2 && !this.expiration.technicalReview) {
      this.formData.technicalReview.cost = null
    } else {
      this.expiration.technicalReview = true
      this.formData.technicalReview.cost = val.components?.find(ic => ic.component.slug === 'technical_review')?.config?.cost
    }
  }
  }
